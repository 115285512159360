import { useObservable, useObservableState, pluckFirst } from "observable-hooks";
import { switchMap, from, of, distinctUntilChanged } from "rxjs";
import type { AnyActorRef } from "xstate";
import { useEffect, useState } from "react";

/**
 * Use this to subscribe to updates from an actor ref that may not be in a system.
 * This is useful for when actors have childrefs that are not in the system.
 */
export const useActorFromRef = <R extends AnyActorRef>(ref?: R) => {
  const ref$ = useObservable(pluckFirst, [ref]);
  const state$ = useObservable(() =>
    ref$.pipe(
      distinctUntilChanged(),
      switchMap((r) => (r ? from(r) : of(null))),
    ),
  );
  const _ref = useObservableState(ref$, ref);
  const state = useObservableState(state$, ref?.getSnapshot() ?? null);

  return [state, _ref] as [ReturnType<R["getSnapshot"]>, R] | [null, null];
};

/**
 * Similar to `useActorFromRef` but requires a function that returns an actor ref.
 */
export const useActor = <R extends AnyActorRef>(fn: () => R) => {
  const [ref, setRef] = useState<R | undefined>();
  useEffect(() => {
    if (!ref) {
      const r = fn();
      setRef(r);
    }
  }, []);
  return useActorFromRef(ref);
};
